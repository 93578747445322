<template>
  <v-row class="ma-3">
    <page-overlay :overlay="isOverlay"/>
    <v-col cols="12" md="12" sm="12" xs="12">
      <v-row>
        <v-col cols="12" md="4" sm="12" xs="12">
          <v-row>
            <v-col cols="12" md="12" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Invoice Details <v-spacer/> <chip-button color="info" text="View Orders" :x-small="false" @onClickHandler="ordersHandler"/></v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details symbol="" title="Refcode" :value="invoice.refcode"/>
                  <list-item-details symbol="" title="Invoice No" :value="invoice.invoiceNo"/>
                  <list-item-details title="Email" :value="invoice.email"/>
                  <list-item-details title="Created At" :value="getDate(invoice.createdAt)"/>
                  <list-item-payment-status title="Payment Status" :status="invoice.paymentStatus"/>
                  <list-item-details symbol="" title="Method" :value="invoice.methods ? invoice.methods.name : ''"/>
                  <list-item-details title="Payment Date" :value="getDate(invoice.paymentDate)"/>
                  <list-item-details symbol="$" :text-bold="true" title="Total" :value="invoice.amount"/>
                  <list-item-details symbol="$" :text-bold="true" title="Discount" :value="invoice.discount"/>
                  <list-item-details symbol="$" :text-bold="true" title="Service Charge" :value="invoice.paymentServiceCharge"/>
                  <list-item-details symbol="$" :text-bold="true" title="Grand Total" :value="invoice.totalAmount"/>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="8" sm="12" xs="12">
          <v-row>
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Who is Created?</v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details title="Name" :value="checkUser(invoice.user)"/>
                  <list-item-details title="Email" :value="checkUser(invoice.user, 'email')"/>
                  <list-item-details title="Date" :value="getDate(invoice.createdAt)"/>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Who is Updated?</v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details title="Name" :value="checkUser(invoice.updater)"/>
                  <list-item-details title="Email" :value="checkUser(invoice.updater, 'email')"/>
                  <list-item-details title="Date" :value="getDate(invoice.updatedAt)"/>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Who is Deleted?</v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details title="Name" :value="checkUser(invoice.deleter)"/>
                  <list-item-details title="Email" :value="checkUser(invoice.deleter, 'email')"/>
                  <list-item-details title="Date" :value="getDate(invoice.deletedAt)"/>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Who is Restored?</v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details title="Name" :value="checkUser(invoice.restorer)"/>
                  <list-item-details title="Email" :value="checkUser(invoice.restorer, 'email')"/>
                  <list-item-details title="Date" :value="getDate(invoice.restoredAt)"/>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" md="6" sm="12" xs="12">
              <v-card class="mx-auto">
                <v-card-title>Email Details <v-spacer/>
                  <chip-button
                    text="View Emails"
                    :x-small="false"
                    @onClickHandler="emailHandler"
                  />
                </v-card-title>
                <v-divider/>
                <v-list dense>
                  <list-item-details title="Payment Mail" :value="getEmailValue(invoice.paymentMail)"/>
                  <list-item-details title="Update Mail" :value="getEmailValue(invoice.updateMail)"/>
                  <list-item-details title="Removal Mail" :value="getEmailValue(invoice.removalMail)"/>
                  <list-item-details title="After Removal Mail" :value="getEmailValue(invoice.afterRemovalMail)"/>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <email-details-popup
        :item="currentItem"
        :show="isShowEmailDetailsPopup"
        @closePopupHandler="closePopupHandler"
    />
    <link-popup
        :item="currentItem"
        :show="isShowLinkPopup"
        @closePopupHandler="closePopupHandler"
    />
  </v-row>
</template>

<script>
import PageOverlay from "../../components/PageOverlay";
import ListItemDetails from "../../components/details/ListItemDetails";
import ListItemPaymentStatus from "../../components/details/ListItemPaymentStatus";
import DateHelper from "../../components/mixins/DateHelper";
import ChipButton from "../../components/buttons/ChipButton";
import EmailDetailsPopup from "../../components/popups/EmailDetailsPopup";
import LinkPopup from "../../components/popups/LinkPopup";
export default {
  name: "InvoiceDetails",
  components: {
    LinkPopup,
    EmailDetailsPopup,
    ChipButton,
    ListItemPaymentStatus,
    ListItemDetails,
    PageOverlay
  },
  data() {
    return {
      invoice: {},
      isOverlay: false,
      currentItem: {},
      isShowEmailDetailsPopup: false,
      isShowLinkPopup: false
    }
  },
  mixins: [DateHelper],
  mounted() {
    this.getInvoice();
  },
  methods: {
    async getInvoice() {
      this.isOverlay = true;
      try {
        let { data: { data: { item } } }  = await this.$store.dispatch('invoice/getWithMethod', { id: this.$route.params.id })
        this.invoice = item;
      } catch (errors) {

      } finally {
        this.isOverlay = false;
      }
    },
    checkUser(user, type = 'name') {
      if (!user) return '';
      return user[type];
    },
    getEmailValue(value) {
      if(value > 1) {
        return `${value} times`
      }

      return `${value} time`
    },
    emailHandler() {
      this.isShowEmailDetailsPopup = true;
      this.currentItem = {invoiceId: this.invoice.id, email: this.invoice.email};
    },
    closePopupHandler() {
      this.isShowEmailDetailsPopup = false;
      this.isShowLinkPopup = false;
    },
    ordersHandler() {
      this.isShowLinkPopup = true
      this.currentItem = this.invoice;
    }
  }
}
</script>

<style scoped>

</style>