<template>
    <v-list-item>
        <v-list-item-content>{{title}}:</v-list-item-content>
        <v-list-item-content class="align-end">
            <strong v-if="textBold">{{symbol}}{{ value }}</strong>
            <p v-else>{{symbol}}{{ value }}</p>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
    export default {
        name: "ListItemDetails",
        props: {
            title: {
                type: String,
                default: function () {
                    return '';
                }
            },
            value: {
                type: [String, Number],
                default: function () {
                    return '';
                }
            },
            symbol: {
                type: String,
                default: function () {
                    return '';
                }
            },
          textBold: {
            type: Boolean,
            default: () => false
          }
        }
    }
</script>

<style scoped>

</style>